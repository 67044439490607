import React, { memo } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';

import { userShape } from 'shapes/user';
import { teamMemberShape } from 'shapes/product';

import connect from 'react/hoc/connectProxy';

import Avatar from 'react/generic/avatar/Avatar';
import UserName from 'react/generic/users/name/UserName';

import { selectBusinessUnitById } from 'redux/businessUnits/selectors';

import { ContactContainer, ContactIcon } from './button/ContactButton';
import classNames from './list.module.scss';

function shouldHaveContact(teamMember, users) {
  if (
    (teamMember.role?.displayOnTop || teamMember.role?.isBuSuitable)
    && users[teamMember.externalId]?.email
  ) {
    return true;
  }
  return false;
}

const enhancer = compose(
  connect(
    (state, props) => ({
      businessUnitName: selectBusinessUnitById(state, props.teamMember?.buReference)?.name,
    }),
  ),

  memo,
);

const ProductTeamMember = ({
  teamMember,
  users,
  businessUnitName,
}) => (
  <li className={classNames.teamMemberElement}>
    <ContactContainer
      externalId={teamMember.externalId}
      disabled={!shouldHaveContact(teamMember, users)}
    >
      <Avatar
        src={users[teamMember.externalId]?.avatar}
        className={classNames.avatar}
        alt=""
      />
    </ContactContainer>
    <div className={classNames.userName}>
      <UserName user={users[teamMember.externalId]} />
      {
        shouldHaveContact(teamMember, users)
        && (
          <div className={classNames.contactIcon}>
            <ContactIcon externalId={teamMember.externalId} />
          </div>
        )
      }
    </div>

    {teamMember.role
      && (
        <div className={classNames.userRole}>
          {teamMember.role.label}
          {businessUnitName && (
            <>
              <br />
              <span>{businessUnitName}</span>
            </>
          )}
        </div>
      )}
  </li>
);

ProductTeamMember.displayName = 'ProductTeamMember';

ProductTeamMember.propTypes = {
  teamMember: teamMemberShape.isRequired,
  // Users indexed by externalId
  users: PropTypes.objectOf(userShape),
  businessUnitName: PropTypes.string,
};

ProductTeamMember.defaultProps = {
  users: {},
  businessUnitName: '',
};

export default enhancer(ProductTeamMember);
