import React, { memo, useState } from 'react';
import { compose } from 'recompose';
import cn from 'classnames';

import connect from 'react/hoc/connectProxy';
import { Field, getFormValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import AutoCompleteSoftwarePublisher from 'react/business/products/form/autocompletes/SoftwarePublishers';

import { withComponentFormName } from 'react/business/components/form/Context';
import globalMessages from 'config/global.messages';
import messages from './softwarePublisher.messages';
import classNames from './softwarePublisher.module.scss';

const enhancer = compose(
  memo,
  connect(
    (state, props) => ({
      softwarePublisher: getFormValues(props.formName)(state).softwarePublisher,
    }),
  ),
  withComponentFormName(),
);

// Validation to show buttons feedback
const haveSoftwarePublisher = (sp) => (sp === undefined ? undefined : !!sp);

const SoftwarePublisherField = ({
  formName, softwarePublisher, change, dispatch,
}) => {
  const [isExternal, setIsExternal] = useState(haveSoftwarePublisher(softwarePublisher));

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <span style={{ marginRight: 10 }}>
          Does Software Publisher is an external company of ADEO?
        </span>
        <div style={{ display: 'inline-block' }}>
          <button
            type="button"
            className={
              cn(
                'unstyled with-pointer',
                classNames.softwarePublisherButton,
                isExternal === true && classNames.softwarePublisherButtonSelected,
              )
            }
            onClick={(event) => {
              event.preventDefault();
              setIsExternal(true);
            }}
            aria-hidden="true"
          >
            <FormattedMessage {...globalMessages.YES} />
          </button>
          <span style={{ margin: '0px 10px' }}>/</span>
          <button
            type="button"
            className={
              cn(
                classNames.softwarePublisherButton,
                'unstyled with-pointer',
                isExternal === false && classNames.softwarePublisherButtonSelected,
              )
            }
            onClick={(event) => {
              event.preventDefault();
              setIsExternal(false);
              dispatch(change(formName, 'softwarePublisher', null, true, false));
            }}
            aria-hidden="true"
          >
            <FormattedMessage {...globalMessages.NO} />
          </button>
        </div>
      </div>
      {isExternal && (
        <Field
          name="softwarePublisher"
          title={<FormattedMessage {...messages.SOFTWARE_PUBLISHER_TITLE} />}
          tooltip={<FormattedMessage {...messages.SOFTWARE_PUBLISHER_HINT} />}
          component={AutoCompleteSoftwarePublisher}
          menuPlacement="top"
        />
      )}
    </div>
  );
};

SoftwarePublisherField.displayName = 'SoftwarePublisherField';

export default enhancer(SoftwarePublisherField);
